import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
const initialState = {
    wallet_history: null,
    isLoading: false,
    cash_history : null,
    withdrawal_history : null
}

export const fetchWalletHistory = createAsyncThunk('/reward-transaction', (params, { rejectWithValue }) => {
    return axios.get(`/reward-transaction`, { params: params }).then(res => res.data)
});
export const fetchCashHistory = createAsyncThunk('/cash-transaction', (params, { rejectWithValue }) => {
    return axios.get(`/cash-transaction`, { params: params }).then(res => res.data)
});

export const fetchWithdrawalHistory = createAsyncThunk('/withdrawal', (params, { rejectWithValue }) => {
    return axios.get(`/withdrawal`, { params: params }).then(res => res.data)
});

export const walletSlice = createSlice({
    name: 'walletHistory',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchWalletHistory.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchWalletHistory.fulfilled, (state, action) => {
            state.wallet_history = action.payload?.data;
            state.isLoading = false;
        })
        builder.addCase(fetchWalletHistory.rejected, (state, action) => {
            state.isLoading = false;
        })
        builder.addCase(fetchCashHistory.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchCashHistory.fulfilled, (state, action) => {
            state.cash_history = action.payload?.data;
            state.isLoading = false;
        })
        builder.addCase(fetchCashHistory.rejected, (state, action) => {
            state.isLoading = false;
        })
        builder.addCase(fetchWithdrawalHistory.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchWithdrawalHistory.fulfilled, (state, action) => {
            state.withdrawal_history = action.payload?.data;
            state.isLoading = false;
        })
        builder.addCase(fetchWithdrawalHistory.rejected, (state, action) => {
            state.isLoading = false;
        })
    
    },
})

export default walletSlice.reducer;

