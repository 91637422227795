import React from "react";

const Participants = ({ participants }) => {
  console.log(participants)
  return (
    <div>
      {participants?.map((each) => (
        <div className="participant">
          <div className="user_image">
            <img src={each?.user_image} alt="" />
          </div>
          <p className="username">{each?.username || ""}</p>
        </div>
      ))}
    </div>
  );
};

export default Participants;
