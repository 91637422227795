import React from "react";
import "./DailyRewardCard.scss";
import parse from "html-react-parser";
import { stringTrim } from "../../utils/utils";
import CoinSvg from "../MissionCard/CoinSvg";
import Button from "../Button/Button";
import "./DailyRewardCard.scss";

const isTooSmall = window.innerWidth < 383;

const DailyRewardCard = ({ data }) => {
  const dailyRewardClickHandler = () => {
    console.log(alert(`${data.reward_title} claim clicked`));
  };
  const isWeeklyReward = data.reward_type == "weeklyreward";
  return (
    <div className="new_mission_card daily_reward_card">
      <div className="flex-column right">
        <div>
          <p className="heading">{data?.reward_title || ""}</p>
          <p className="description">
            {parse(
              stringTrim(data?.reward_description, isTooSmall ? 100 : 110)
            )}
          </p>
        </div>
        <div className="botton_cont">
          <div className="left_cont">
            {isWeeklyReward && (
              <div className="weekly_cart">
                <div className="check active">1</div>
                <div className="check">2</div>
                <div className="check">3</div>
                <div className="check">4</div>
                <div className="check">5</div>
                <div className="check">6</div>
                <div className="check">7</div>
              </div>
            )}
          </div>
          <div className="flex_between mt-auto">
            <div className="aff_btn_cont">
              <div className="affliate_coins">
                <CoinSvg />
                <p className="coins">AP {data?.reward_points || 0}</p>
              </div>
              <Button
                isGreen
                isSmall
                title={isWeeklyReward ? "Check In" : "Claim"}
                className="join_btn"
                onClick={dailyRewardClickHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyRewardCard;
