import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import { AiFillHome, AiFillGift } from "react-icons/ai";
import { MdWorkspacesFilled } from "react-icons/md";
import { BsCashCoin } from "react-icons/bs";
import { BiHelpCircle } from "react-icons/bi";
import { GiTwoCoins } from "react-icons/gi";
import { FaCoins } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { my_icons } from "../../../assests/jsxSvgs/jsxSvgsExport";
import { clearUserInfo, levelCalculator } from "../../../redux/user/userSlice";
import { stringTrim } from "../../../utils/utils";
import "./SideNav.scss";
import { FaUserAlt } from "react-icons/fa";
import Button from "../../Button/Button";

const SideNav = ({ closer ,show }) => {
  const navigate = useNavigate();
  const navitemClickHandler = (to) => {
    closer();
    navigate(to);
  };
  const { user } = useSelector((data) => data.user || {});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const userSignOut = () => {
    closer();
    dispatch(clearUserInfo());
    navigate("/signin");
  };

  const signInHandler = () => {
    setLoading(true);
    userSignOut();
  };
  const LinkCopyHandler = () => {
    let referalLink = `${origin}/register/?referalCode=${user?.user?.referal_code}`;
    navigator?.clipboard?.writeText(referalLink);
    toast.success("Referral Link copied..");
  };

  const level = useSelector(levelCalculator);
  return ReactDOM.createPortal(
    <>
      <div className="back_drop" onClick={closer}></div>
      <div className="side_nav_root side_nav_padding new_side_nav">
        <div className="flex flex-column h-100 justify-between">
          <div className="content">
            <Link
              to="/profile"
              className=" side-nav-profile flex justify-between"
            >
              <div className="flex align-center">
                <div className="profile_img_cont">
                  {user?.user?.profile_image ? (
                    <img src={`${user?.user?.profile_image}`} alt="" />
                  ) : (
                    <FaUserAlt />
                  )}
                </div>
                <div className="flex flex-column">
                  <p className="profile_name">
                    {stringTrim(user?.user?.name, 19) || "User"}
                  </p>
                  <p className="level_container">
                    Level <span className="level">{level || 1}</span>
                  </p>
                </div>
              </div>
              <div className="icon_cont">{my_icons.chevronRigh}</div>
            </Link>

            <div className="menu_items_new">
              <div
                onClick={() => {
                  navitemClickHandler("/");
                }}
                className="menu_item_new flex align-center"
              >
                <div className="icon_cont">
                  <AiFillHome />
                </div>
                <p className="menu_text">Home</p>
              </div>
              <div
                onClick={() => {
                  navitemClickHandler("/missions");
                }}
                className="menu_item_new flex align-center"
              >
                <div className="icon_cont">
                  <MdWorkspacesFilled />
                </div>
                <p className="menu_text">Missions</p>
              </div>
              <div
                onClick={() => {
                  navitemClickHandler("/daily-rewards");
                }}
                className="menu_item_new flex align-center"
              >
                <div className="icon_cont">
                  <FaCoins />
                </div>
                <p className="menu_text">Daily Rewards</p>
              </div>
            </div>
          </div>
          <div className="p-20 singout_cont">
            <Button
              onClick={signInHandler}
              isGreen
              title={user?.user ? "Sign Out" : "Sign In"}
            />
          </div>
        </div>
      </div>
    </>,
    document.getElementById("side_nav")
  );
};

export default SideNav;
