import React from "react";
import "./MissionCard.scss";
import CoinSvg from "./CoinSvg";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { stringTrim } from "../../utils/utils";

const isTooSmall = window.innerWidth < 383;
const MissionCard = ({ data }) => {
  const navigate = useNavigate();
  const missionClickHandler = () => {
    navigate(`/mission/${data?.id}`);
  };
  return (
    <div className="new_mission_card">
      <div className="mission_image_cont">
        <img src={data?.banner_image} alt="" />
      </div>
      <div className="flex-column right">
        <div>
          <p className="heading">{data?.mission_title || ""}</p>
          <p className="description">
            {parse(stringTrim(data?.mission_description, isTooSmall ? 45 : 60))}
          </p>
        </div>

        <div className="flex_between mt-auto">
          <div className="aff_btn_cont">
            <div className="affliate_coins">
              <CoinSvg />{" "}
              <p className="coins">
                AP {data?.enter_earn_affliated_points || 0}
              </p>
            </div>
            <Button
              isGreen
              isSmall
              title="Join"
              className="join_btn"
              onClick={missionClickHandler}
            />
          </div>
        </div>

        {/* <div className="status_cont">{data?.mission_status}</div> */}
      </div>
    </div>
  );
};

export default MissionCard;
