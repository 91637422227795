import React from "react";
import "./GoBackButton.css";
import { useNavigate } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
const GoBackButton = () => {
  const navigate = useNavigate();
  return (
    <div onClick={() => navigate(-1)} className="goBackButton">
      <FiChevronLeft />
    </div>
  );
};

export default GoBackButton;
