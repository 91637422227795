import React from "react";

const User = (props) => {
  return (
    <svg
      height="19"
      width="25"
      fill="none"
      viewBox="0 0 25 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0909 8.5C18.1908 8.5 19.9091 6.81238 19.9091 4.75C19.9091 2.68769 18.1908 1 16.0909 1C13.9909 1 12.2728 2.68769 12.2728 4.75C12.2728 6.81238 13.9909 8.5 16.0909 8.5ZM6.90913 8.5C9.00906 8.5 10.7273 6.81238 10.7273 4.75C10.7273 2.68769 9.00906 1 6.90913 1C4.80919 1 3.09088 2.68769 3.09088 4.75C3.09088 6.81238 4.80919 8.5 6.90913 8.5ZM6.90913 11.25C3.918 11.25 0 12.6874 0 15.625V19H14V15.625C14 12.6874 9.90006 11.25 6.90913 11.25ZM15.0909 11.9377C14.7093 11.9377 14.4456 11.9377 14 12C15.4641 13.0626 16 13.75 16 15.625V19H24V15.625C24 12.6874 18.082 11.9377 15.0909 11.9377Z"
        fill="#1D1D1D"
        fillOpacity="0.2"
      />
      <path
        d="M17.0909 7.5C19.1908 7.5 20.9091 5.81238 20.9091 3.75C20.9091 1.68769 19.1908 0 17.0909 0C14.9909 0 13.2728 1.68769 13.2728 3.75C13.2728 5.81238 14.9909 7.5 17.0909 7.5ZM7.90913 7.5C10.0091 7.5 11.7273 5.81238 11.7273 3.75C11.7273 1.68769 10.0091 0 7.90913 0C5.80919 0 4.09088 1.68769 4.09088 3.75C4.09088 5.81238 5.80919 7.5 7.90913 7.5ZM7.90913 10.25C4.918 10.25 1 11.6874 1 14.625V18H15V14.625C15 11.6874 10.9001 10.25 7.90913 10.25ZM16.0909 10.9377C15.7093 10.9377 15.4456 10.9377 15 11C16.4641 12.0626 17 12.75 17 14.625V18H25V14.625C25 11.6874 19.082 10.9377 16.0909 10.9377Z"
        fill="#00B9F7"
      />
    </svg>
  );
};

export default User;

