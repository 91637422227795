import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import Dashboard from "../NewDesignPages/Dashboard/Dashboard";
import LoginPage from "../NewDesignPages/LoginPage/LoginPage";
import ForgotPassword from "../NewDesignPages/ForgotPassword/ForgotPassword";
import RegisterPage from "../NewDesignPages/RegisterPage/RegisterPage";
// import Dashboard from "../Pages/Dashboard/Dashboard";

const AuthRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<Dashboard />} /> */}
      <Route path="/" element={<Dashboard />} />
      <Route path="/signin" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/register/:refId" element={<RegisterPage />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset/:token/:email" element={<ResetPassword />} />
      <Route path="*" element={<Navigate replace to="/signin" />} />
    </Routes>
  );
};

export default AuthRoutes;
