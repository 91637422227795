import React from "react";
import "./DailyRewardsPage.scss";
import { useSelector } from "react-redux";
import Loader from "../../NewComponents/Loader/Loader";
import MissionCard from "../../NewComponents/MissionCard/MissionCard";
import NavBar from "../../NewComponents/NavBar/NavBar";
import { selectDailyRewardsStore } from "../../redux/dailyRewardsSlice";
import DailyRewardCard from "../../NewComponents/DailyRewardCard/DailyRewardCard";

const DailyRewardsPage = () => {
  const dailyRewardsData = useSelector(selectDailyRewardsStore);
  return (
    <div className="page">
      <NavBar />
      {dailyRewardsData.isLoading && <Loader />}
      <div className="p-20">
        <p className="section_heading new">Daily Rewards</p>
        <div className="new_missions_cont">
          {dailyRewardsData?.data?.map((each) => (
            <DailyRewardCard data={each} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DailyRewardsPage;
