import React from "react";
import "./Dashboard.scss";
import NavBar from "../../NewComponents/NavBar/NavBar";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Tab from "./Tab/Tab";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchMissions, selectMissionStore } from "../../redux/missionSlice";
import MissionCard from "../../NewComponents/MissionCard/MissionCard";
import { getUrlParam } from "../../utils/utils";
import { TAB_TYPES } from "./data";
import { useNavigate } from "react-router-dom";
import Loader from "../../NewComponents/Loader/Loader";
import {
  fetchDailyRewardsAction,
  selectDailyRewardsStore,
} from "../../redux/dailyRewardsSlice";
import DailyRewardCard from "../../NewComponents/DailyRewardCard/DailyRewardCard";

const Dashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMissions());
    dispatch(fetchDailyRewardsAction());
  }, []);

  const missionsData = useSelector(selectMissionStore);
  const dailyRewardsData = useSelector(selectDailyRewardsStore);

  const tabType = getUrlParam("type");
  const isDailyReward = tabType == TAB_TYPES.daily_reward;

  const navigate = useNavigate();
  const tabClickHandler = (type) => {
    navigate(`/?type=${type}`);
  };

  console.log(dailyRewardsData, "some daily rewards");

  return (
    <div className="page">
      {missionsData.isLoading && <Loader />}
      <NavBar />
      <div className="">
        <Carousel showThumbs={false}>
          <img src={require("./bajiBanner.png")} alt="" />
          <img src={require("./bajiBanner.png")} alt="" />
          <img src={require("./bajiBanner.png")} alt="" />
          <img src={require("./bajiBanner.png")} alt="" />
        </Carousel>
      </div>

      <div className="p-20 pt-10">
        <div className="flex gap-10 align-center">
          <Tab
            tabClickHandler={tabClickHandler}
            isActive={!isDailyReward}
            label="Missions"
            type={TAB_TYPES.missions}
          />
          <Tab
            tabClickHandler={tabClickHandler}
            isActive={isDailyReward}
            label="Daily Rewards"
            type={TAB_TYPES.daily_reward}
          />
        </div>
        {isDailyReward ? (
          <div className="new_missions_cont">
            {dailyRewardsData?.data?.map((each) => (
              <DailyRewardCard data={each} />
            ))}
          </div>
        ) : (
          <div className="new_missions_cont">
            {missionsData?.data?.map((each) => (
              <MissionCard data={each} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
