
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios'
import { redirectToCallbackUrl } from '../../utils/utils';
const initialState = {
    isLoading: false,
    user: null,
    wallet: null,
    referral_count: 0

}

export const signInUser = createAsyncThunk('/login', (data, { rejectWithValue }) => {
    return axios.post('/login', data.payload).then(res => {
        if (res.data?.success) {
            data.cb(true, res.data)
            return res.data
        }
        else {
            data.cb(false, res?.data)
            return rejectWithValue(res?.data?.message);
        }
    }
    ).catch(e => {
        data.cb(false, e.response?.data)
        throw new Error(e.response?.data)
    })
});
export const fetchWallet = createAsyncThunk('/userwalletap', (data, { rejectWithValue }) => {
    return axios.get('/userwalletap').then(res => {
        if (res.data?.status == 200) {
            return res.data
        }
        else {
            data.cb(false, res?.data)
            return rejectWithValue(res?.data?.message);
        }
    }
    ).catch(e => {
        data.cb(false, e.response?.data)
        throw new Error(e.response?.data)
    })
});

export const signupUser = createAsyncThunk('/register', (data, { rejectWithValue }) => {
    return axios.post(data.url, data.payload).then(res => {
        let user
        if (res.data?.success) {
            data.cb(true, res.data)
            user = res.data
            return user
        }
        else {
            data.cb(false, res?.data)
            return rejectWithValue(res?.data)
        }

    }
    ).catch(e => {
        data.cb(false, e.response?.data)
        throw new Error(e.response?.data)

    })
});

export const fetchReferralCount = createAsyncThunk('/referral-count', () => {
    return axios.get('/referral-count').then(res => {
        return res?.data
    }).catch(e => {
        throw new Error(e.response?.data)
    })
})

export const levelCalculator = (state) => {
    const referral_count = state.user?.referral_count;
    if (referral_count < 10)  return "1"
    else if (referral_count <20) return "2"
    else if (referral_count <50) return "3"
    else if (referral_count <200) return "4"
    else return "5"
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserInfo: (state, action) => {
            state.user = action.payload;
        },
        clearUserInfo: (state, action) => {
            localStorage.removeItem('userDetails')
            state.user = null
            state.isLoading = false
        }
    },
    extraReducers: {

        [signInUser.pending]: (state) => {
            state.isLoading = true;
        },
        [signInUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            let user = action.payload?.data;
            localStorage.setItem('userDetails', JSON.stringify(user))
            redirectToCallbackUrl()
            state.user = user

        },
        [signInUser.rejected]: (state) => {
            state.isLoading = false;
        },

        //////// REFERAL COUNT FETCH///////////////
        [fetchReferralCount.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchReferralCount.fulfilled]: (state, action) => {
            state.referral_count = action.payload?.data?.referral_count || 0
        },
        [fetchReferralCount.rejected]: (state) => {
            state.isLoading = false;
        },
        //////////////////////////////////////////

        [signupUser.pending]: (state) => {
            state.isLoading = true;
        },
        [signupUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            let user = action.payload?.data;
            localStorage.setItem('userDetails', JSON.stringify(user))
            redirectToCallbackUrl()
            state.user = user;
        },
        [signupUser.rejected]: (state) => {
            state.isLoading = false;
        },
        [fetchWallet.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchWallet.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.wallet = action.payload.data;
        },
        [fetchWallet.rejected]: (state) => {
            state.isLoading = false;
        },
    },
})


export const { setUserInfo, clearUserInfo } = userSlice.actions

export default userSlice.reducer;
