import React from "react";
import "./MissionsPage.scss";
import { useSelector } from "react-redux";
import Loader from "../../NewComponents/Loader/Loader";
import { selectMissionStore } from "../../redux/missionSlice";
import MissionCard from "../../NewComponents/MissionCard/MissionCard";
import NavBar from "../../NewComponents/NavBar/NavBar";

const MissionsPage = () => {
  const missionsData = useSelector(selectMissionStore);
  return (
    <div className="page">
      <NavBar />
      {missionsData.isLoading && <Loader />}
      <div className="p-20">
        <p className="section_heading new">Missions</p>
        <div className="new_missions_cont">
          {missionsData?.data?.map((each) => (
            <MissionCard data={each} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MissionsPage;
