import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TextField from "../../components/Input/TextField";
import axios from "axios";
import Button from "../../NewComponents/Button/Button";
import Loader from "../../NewComponents/Loader/Loader";

const ForgotPassword = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const cb = (success, res) => {
    setLoading(false);
    if (success && !res?.data?.errors) {
      toast.success(
        res?.data?.message || "Reset Link sent to Your Email Successfully"
      );
    } else {
      if (res?.data?.errors) {
        setErrors(res?.data?.errors);
        return;
      }
      toast.error(
        res?.data?.message || res?.message || "Something went wrong, Try again"
      );
    }
  };

  const inputChangeHandler = (e) => {
    let name = e.target.name;
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/forgetPassword", values)
      .then((res) => {
        if (res?.data?.success) {
          cb(true, res);
        } else {
          cb(false, res);
        }
      })
      .catch((e) => {
        cb(false, e?.response?.data);
      });
  };
  return (
    <>
      <Loader
       loading={loading} />
      <div className="page new_login_page">
        <div className="logo-cont">
          <img
            src={require("../../assests/Images/bonus_logo.png")}
            alt=""
            className="bonus_logo"
          />
        </div>
        <div className="p-20 bottom">
          <p className="form-heading">Forgot Password ?</p>
          <form onSubmit={formSubmitHandler}>
            <TextField
              className={` ${errors?.email ? " error" : ""}`}
              name="email"
              label="Email"
              type="text"
              setValues={setValues}
              values={values}
              onChange={inputChangeHandler}
              required
            />
            {errors?.email?.map((each) => (
              <p className="error_msg">{each}</p>
            ))}
            <Button className="w-100" isGreen title="Proceed" />{" "}
          </form>
          <div className="mt-auto sign-up-cont ">
            <div className=" p-cont flex align-center justify-center">
              <p className="mr-5 white_text">Back to</p>
              <Link className="green_text" to={`/signin`}>
                Sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
