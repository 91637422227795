import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./GoBackHeader.css";
import ChevronLeft from "../../assests/jsxSvgs/ChevronLeft";
import { useDispatch, useSelector } from "react-redux";
import { fetchWallet } from "../../redux/user/userSlice";

const GoBackHeader = ({
  title,
  goBackHandler,
  AfterBackCallback = () => {},
  hideWallet = false,
}) => {
  const navigate = useNavigate();
  const backClickHandler = () => {
    navigate(-1);
    AfterBackCallback();
  };

  const walletInfoclick = () => {
    navigate("/wallet-info");
  };
  const cashInfoclick = () => {
    navigate("/cash-info");
  };

  const { wallet } = useSelector((store) => store?.user || {});

  const dispatch = useDispatch();
  useEffect(() => {
    if (!wallet?.total_ap) dispatch(fetchWallet());
  }, []);
  return (
    <div className="go_back_header flex align-center justify-between">
      <div className="flex align-center ">
        <div
          className="back_icon icon_cont mr-20"
          onClick={goBackHandler || backClickHandler}
        >
          <ChevronLeft size="25" />
        </div>
        <p className="go_back_title">{title}</p>
      </div>
      {!hideWallet && (
        <div>
          <div className="flex align-center gap-15">
            <div onClick={walletInfoclick} className="coins_cont">
              {wallet?.total_ap || 0} AP
            </div>
            <div onClick={cashInfoclick} className="coins_cont light">
              INR {wallet?.total_wallet || 0}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GoBackHeader;
