import React from "react";
import { GrClose } from "react-icons/gr";

const ModalHeader = ({ title, closeModal, className }) => {
  return (
    <div className={"modal-header flex justify-between " + className}>
      <p className="modal-title text-primary">{title}</p>
      <GrClose size={20} onClick={closeModal} />
    </div>
  );
};

export default ModalHeader;
