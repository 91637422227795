import React from "react";
import { useState } from "react";
import { my_icons } from "../../assests/jsxSvgs/jsxSvgsExport";
import "./TextField.css";

const TextField = ({
  values,
  label,
  name,
  type = "text",
  onChange,
  className,
  required,
  disabled,
  isTextArea,
}) => {
  const [textType, setTextType] = useState("password");

  const toggleShow = () => {
    setTextType(textType == "password" ? "text" : "password");
  };
  return (
    <div
      className={`basic-input ${disabled ? " disabled" : " "}  ${className}`}
    >
      <div className="flex align-center justify-between">
        <div style={{ flex: 1 }}>
          {values?.[name] && <p className="label">{label}</p>}
          {isTextArea ? (
            <textarea
              required={required}
              value={values[name] || ""}
              type={type == "password" ? textType : type}
              className="input"
              placeholder={label}
              name={name}
              onChange={onChange}
              disabled={disabled}
            />
          ) : (
            <input
              required={required}
              value={values[name] || ""}
              type={type == "password" ? textType : type}
              className="input"
              placeholder={label}
              name={name}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        </div>
        {type === "password" && (
          <div onClick={toggleShow} className="icon_cont">
            {textType === "password" ? my_icons.eyeClose : my_icons.eye}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextField;
