import React from "react";
import "./Button.scss";

const Button = ({ title, className, isGreen, isSmall, onClick, ...props }) => {
  return (
    <button
      onClick={onClick}
      {...props}
      className={`new_button  ${isSmall && " small"} ${isGreen && " green"} ${
        className
      }`}
    >
      {title}
    </button>
  );
};

export default Button;
