import React from "react";
import { my_icons } from "../../assests/jsxSvgs/jsxSvgsExport";

const NoNotifications = () => {
  return (
    <div className="no-notifications-root">
      <div className="no-notifications-cont">{my_icons.noNotifications}</div>
      <p className="no-notification-msg">
        You have no notifications right now Come back later
      </p>
    </div>
  );
};

export default NoNotifications;
