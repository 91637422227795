import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { toast } from "react-toastify";
import TextField from "../../components/Input/TextField2";
import "./ProfilePage.css";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import { my_icons } from "../../assests/jsxSvgs/jsxSvgsExport";
import { AiFillCloseCircle, AiFillPlusCircle } from "react-icons/ai";
import axios from "axios";
import { setUserInfo } from "../../redux/user/userSlice";
import { imageBasePath } from "../../utils/utils";
import { country_names } from "../RegisterPage/data";

const ProfilePage = () => {
  const { user } = useSelector((store) => store.user.user || {});
  const { user: MainUser } = useSelector((store) => store?.user);
  const [values, setValues] = useState({
    name: user?.name || "",
    user_name: user?.user_name || "",
    email: user?.email || "",
    logo: user?.logo,
    current_password: "",
    new_password: "",
    phone: user?.phone,
  });
  const [disableBtn, setDisableBtn] = useState(true);
  const [updatedImage, setUpdateImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const cb = (success, res) => {
    if (success) {
      toast.success(res?.message || "User Created Successfully");
      navigate("/");
    } else {
      if (res?.errors) {
        setErrors(res?.errors);
        return;
      }
      toast.error(res?.message || "Something went wrong, Try again");
    }
  };

  const dispatch = useDispatch();

  const updateUserRedux = (updateValues, response) => {
    console.log(response)
    let updateUser = { ...MainUser };
    let user = { ...MainUser?.user, ...updateValues };
    if (response?.user?.logo) {
      user.profile_image = response?.user?.profile_image;
      setValues({ ...values, logo: response?.user?.logo });
    }
    updateUser.user = user;
    localStorage.setItem("userDetails", JSON.stringify(updateUser));
    dispatch(setUserInfo(updateUser));
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("phone", values.phone);
    formData.append("current_password", values.current_password);
    formData.append("new_password", values.new_password);
    if (updatedImage) {
      formData.append("logo", updatedImage);
    }
    setLoading(true);
    axios
      .post("/profile-update", formData)
      .then((res) => {
        if (res?.data?.success) {
          let updateValues = {
            ...values,
          };
          if (updatedImage) {
            setValues(updateValues);
            setUpdateImage(null);
          }
          setLoading(false);
          updateUserRedux(updateValues, res?.data?.data);
          toast.success(res?.data?.message || "Updated Successfully!");
        } else {
          setLoading(false);
          setErrors(res?.data.message);
          toast.error("Field Errors");
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(
          e?.response?.data?.message || "Something went wrong try again..."
        );
      });
    // alert(JSON.stringify(values));
    // dispatch(profileUpadateAction({ payload: values, cb: cb }));
  };
  const { isLoading } = useSelector((store) => store.user);

  const inputChangeHandler = (e) => {
    if (disableBtn) {
      setDisableBtn(false);
    }
    let name = e.target.name;
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  const imageChangeHandler = (e) => {
    setDisableBtn(false);
    setUpdateImage(e?.target?.files?.[0]);
  };

  const ProfileImageUrlGenerate = useMemo(() => {
    return updatedImage ? URL.createObjectURL(updatedImage) : false;
  }, [updatedImage]);
  return (
    <div className="profile-page">
      <GoBackHeader title="Profile" />
      <Loader loading={isLoading || loading} />
      <div className="register-page">
        <div className="bottom-cont">
          <form onSubmit={formSubmitHandler}>
            <div className="profile-img-cont mt-25 mb-25">
              {updatedImage || values?.logo ? (
                <img
                  className="profile-img"
                  src={updatedImage ? ProfileImageUrlGenerate : values?.logo}
                  alt=""
                />
              ) : (
                my_icons.user
              )}
              {updatedImage ? (
                <div
                  onClick={() => setUpdateImage(null)}
                  className="profile_update_btn"
                >
                  <AiFillCloseCircle />
                </div>
              ) : (
                <label for="file" className="profile_update_btn">
                  <AiFillPlusCircle />
                </label>
              )}
              <input
                onChange={imageChangeHandler}
                id="file"
                type="file"
                className="profileImageInput"
              />
            </div>
            <div className="flex flex-column">
              <TextField
                className={` ${errors?.full_name ? " error" : ""}`}
                name="name"
                label="Full Name"
                type="text"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.full_name?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <div className="flex flex-column">
              <TextField
                className={` ${errors?.user_name ? " error" : ""}`}
                name="user_name"
                label="Username"
                type="text"
                setValues={setValues}
                values={values}
                onChange={inputChangeHandler}
                disabled
              />
              {errors?.user_name?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <div className="flex flex-column">
              <TextField
                className={` ${errors?.email ? " error" : ""}`}
                name="email"
                label="Email"
                type="email"
                setValues={setValues}
                values={values}
                onChange={inputChangeHandler}
                disabled
              />
              {errors?.email?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>
            <div className="flex flex-column">
              <TextField
                className={` ${errors?.email ? " error" : ""}`}
                name="current_password"
                label="Current Password"
                type="password"
                setValues={setValues}
                values={values}
                onChange={inputChangeHandler}
              />
              {errors?.current_password?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>
            <div className="flex flex-column">
              <TextField
                className={` ${errors?.email ? " error" : ""}`}
                name="new_password"
                label="New Password"
                type="password"
                setValues={setValues}
                values={values}
                onChange={inputChangeHandler}
              />
              {errors?.new_password?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>
            <div className="flex flex-column">
              <TextField
                className={` ${errors?.phone ? " error" : ""}`}
                name="phone"
                label="Phone"
                type="text"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.phone?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <button
              disabled={disableBtn}
              className="btn btn-primary update_profile_btn "
            >
              Update Profile
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
