import React from "react";
import Tab_bg from "./Tab_bg";
import "./Tab.scss";

const Tab = ({ isActive, label, tabClickHandler, data, type }) => {
  return (
    <div
      onClick={() => tabClickHandler(type)}
      className={`tab ${isActive && " is_active"}`}
    >
      {isActive && (
        <div className="bg">
          <Tab_bg />
        </div>
      )}
      <p className="label">{label}</p>
    </div>
  );
};

export default Tab;
