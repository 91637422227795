import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { signInUser } from "../../redux/user/userSlice";
import "./LoginPage.scss";
import { toast } from "react-toastify";
import TextField from "../../components/Input/TextField";
import Button from "../../NewComponents/Button/Button";
import Loader from "../../NewComponents/Loader/Loader";

const LoginPage = () => {
  const [values, setValues] = useState({
    username: "test1@gmail.com",
    password: "test1@gmail.com",
  });
  const [errors, setErrors] = useState({});

  const cb = (success, res) => {
    if (success) {
      toast.success(res?.data?.message || "User Logged in Successfully");
    } else {
      if (res?.errors) {
        setErrors(res?.errors);
        return;
      }
      toast.error(
        res?.data?.message || res?.message || "Something went wrong, Try again"
      );
    }
  };

  const dispatch = useDispatch();

  const inputChangeHandler = (e) => {
    let name = e.target.name;
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(signInUser({ payload: values, cb: cb }));
  };
  const { isLoading } = useSelector((store) => store.user);

  return (
    <>
      <Loader
       color={"#2de582"}
       loading={isLoading} />

      <div className="page new_login_page">
        <div className="logo-cont">
          <img
            src={require("../../assests/Images/bonus_logo.png")}
            alt=""
            className="bonus_logo"
          />
        </div>
        <div className="p-20 bottom">
          <p className="form-heading">
            Sign in
          </p>
          <form onSubmit={formSubmitHandler}>
            <TextField
              className={` ${errors?.username ? " error" : ""}`}
              name="username"
              label="Username or Email"
              type="text"
              setValues={setValues}
              values={values}
              onChange={inputChangeHandler}
              required
            />
            <TextField
              className={` ${errors?.password ? " error" : ""}`}
              name="password"
              label="Password"
              type="password"
              setValues={setValues}
              values={values}
              onChange={inputChangeHandler}
              required
            />
            <div className="flex ">
              <Link to="/forgot-password" className="forgot_password">
                Forgot Password?
              </Link>
            </div>
            <Button 
            className="w-100"
            isGreen
            title="Sign In"
             />
          </form>
          <div className=" mt-auto sign-up-cont ">
            <div className=" p-cont flex align-center justify-center">
              <p className="mr-5 white_text">Don't Have Account?</p>
              <Link className="green_text" to={`/register`}>
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
