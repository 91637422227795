import React, { useState } from "react";
import "./NavBar.scss";
import { navIcons } from "./icons/icons";
import SideNav from "./SideNav/SideNav";

const NavBar = () => {
  const [showSideNav, setShowSideNav] = useState(false);

  const closeSideNav = () => {
    setShowSideNav(false);
  };
  const openSideNav = () => {
    setShowSideNav(true);
  };
  return (
    <>
      {showSideNav && <SideNav closer={closeSideNav} show={showSideNav} />}
      <div className="navbar_new">
        <div onClick={openSideNav} className="square_icon">
          {navIcons.menu_icon}
        </div>
        <div className="bj_rupee_cont">
          <div className="rounded_pill_icon">
            <span className="icon">{navIcons.user_icon}</span>
            <span className="text">300</span>
          </div>
          <div className="rounded_pill_icon">
            <span className="text">300</span>
            <span className="icon">{navIcons.rupee_icon}</span>
          </div>
        </div>
        <div className="square_icon">{navIcons.bell_icon}</div>
      </div>
    </>
  );
};

export default NavBar;
