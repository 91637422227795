import React from "react";

const Tab_bg = (props) => {
  return (
    <svg
      height="56"
      width="188"
      fill="none"
      preserveAspectRatio="none"
      viewBox="0 0 188 56"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M17.9349 56H170.065C175.632 56 180.468 52.1716 181.745 46.7533L187.099 24.0394C188.676 17.3493 184.343 10.7207 177.562 9.59372C157.199 6.20916 116.743 0 94 0C71.2565 0 30.8004 6.20917 10.4376 9.59373C3.6573 10.7207 -0.676186 17.3493 0.900796 24.0393L6.25497 46.7532C7.53219 52.1715 12.3681 56 17.9349 56Z"
          fill="url(#paint0_linear_2_390)"
        />
        <path
          d="M170.065 55H17.9349C12.832 55 8.39908 51.4906 7.22829 46.5238L1.87412 23.8099C0.427542 17.6731 4.40198 11.6107 10.6016 10.5802C30.9845 7.1923 71.3549 1 94 1C116.645 1 157.015 7.19228 177.398 10.5802C183.598 11.6106 187.572 17.6731 186.126 23.8099L180.771 46.5239C179.601 51.4906 175.168 55 170.065 55Z"
          stroke="url(#paint1_linear_2_390)"
          strokeWidth="2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2_390"
          gradientUnits="userSpaceOnUse"
          x1="94"
          x2="94"
          y1="0"
          y2="56"
        >
          <stop stopColor="white" stopOpacity="0.17" />
          <stop offset="1" stopColor="white" stopOpacity="0.07" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_390"
          gradientUnits="userSpaceOnUse"
          x1="94"
          x2="94"
          y1="-37.1304"
          y2="28"
        >
          <stop stopColor="white" stopOpacity="0.6" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Tab_bg;
