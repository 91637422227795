import React from "react";

const CoinSvg = (props) => {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_210_1327)">
        <path
          d="M12 23.6484C5.56675 23.6484 0.351562 18.4333 0.351562 12C0.351562 5.56675 5.56675 0.351562 12 0.351562C18.4333 0.351562 23.6484 5.56675 23.6484 12C23.6484 18.4333 18.4333 23.6484 12 23.6484Z"
          fill="#26BEFF"
        />
        <path
          d="M12 21.7734C6.60228 21.7734 2.22656 17.3977 2.22656 12C2.22656 6.60228 6.60228 2.22656 12 2.22656C17.3977 2.22656 21.7734 6.60228 21.7734 12C21.7734 17.3977 17.3977 21.7734 12 21.7734Z"
          fill="#5BCEFF"
        />
        <path
          d="M14.5454 11.25C15.5954 11.25 16.4546 10.4062 16.4546 9.375C16.4546 8.34384 15.5954 7.5 14.5454 7.5C13.4955 7.5 12.6364 8.34384 12.6364 9.375C12.6364 10.4062 13.4955 11.25 14.5454 11.25ZM9.95456 11.25C11.0045 11.25 11.8636 10.4062 11.8636 9.375C11.8636 8.34384 11.0045 7.5 9.95456 7.5C8.90459 7.5 8.04544 8.34384 8.04544 9.375C8.04544 10.4062 8.90459 11.25 9.95456 11.25ZM9.95456 12.625C8.459 12.625 6.5 13.3437 6.5 14.8125V16.5H13.5V14.8125C13.5 13.3437 11.45 12.625 9.95456 12.625ZM14.0454 12.9688C13.8547 12.9688 13.7228 12.9688 13.5 13C14.2321 13.5313 14.5 13.875 14.5 14.8125V16.5H18.5V14.8125C18.5 13.3437 15.541 12.9688 14.0454 12.9688Z"
          fill="#1D1D1D"
          fillOpacity="0.2"
        />
        <path
          d="M15.0454 10.75C16.0954 10.75 16.9546 9.90619 16.9546 8.875C16.9546 7.84384 16.0954 7 15.0454 7C13.9955 7 13.1364 7.84384 13.1364 8.875C13.1364 9.90619 13.9955 10.75 15.0454 10.75ZM10.4546 10.75C11.5045 10.75 12.3636 9.90619 12.3636 8.875C12.3636 7.84384 11.5045 7 10.4546 7C9.40459 7 8.54544 7.84384 8.54544 8.875C8.54544 9.90619 9.40459 10.75 10.4546 10.75ZM10.4546 12.125C8.959 12.125 7 12.8437 7 14.3125V16H14V14.3125C14 12.8437 11.95 12.125 10.4546 12.125ZM14.5454 12.4688C14.3547 12.4688 14.2228 12.4688 14 12.5C14.7321 13.0313 15 13.375 15 14.3125V16H19V14.3125C19 12.8437 16.041 12.4688 14.5454 12.4688Z"
          fill="#F5F8F9"
        />
        <path
          d="M12 2.22656C12.3523 2.22665 12.7044 2.24574 13.0547 2.28375C8.16 2.8125 4.33594 6.96703 4.33594 12C4.33594 17.033 8.16 21.1875 13.0547 21.7162C12.7044 21.7543 12.3523 21.7734 12 21.7734C6.60938 21.7734 2.22656 17.3906 2.22656 12C2.22656 6.60938 6.60938 2.22656 12 2.22656Z"
          fill="#1D1D1D"
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_210_1327">
          <rect
            height="24"
            width="24"
            fill="white"
            transform="matrix(-1 0 0 1 24 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CoinSvg;
