import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
const initialState = {
    data: null,
    isLoading: false,
}

export const fetchMyWinningPrizes = createAsyncThunk('/lotter-prize', () => {
    return axios.get('/lotter-prize').then(res => res.data)
});


export const fetchNotificationSlice = createSlice({
    name: 'winningSlice',
    initialState,
    extraReducers: {
        [fetchMyWinningPrizes.pending]: (state) => {
            if (!state?.data?.length)
                state.isLoading = true;
        },
        [fetchMyWinningPrizes.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.data = action?.payload?.data
        },
        [fetchMyWinningPrizes.rejected]: (state) => {
            state.isLoading = false;
        },
    },
})

export default fetchNotificationSlice.reducer;

