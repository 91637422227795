import React from "react";

const Rupee = (props) => {
  return (
    <svg
      height="20"
      width="18"
      fill="none"
      viewBox="0 0 18 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M15.3484 4.53286C16.0698 4.93095 16.4305 5.51905 16.4305 6.29714C16.4305 6.91238 16.1837 7.48238 15.6901 8.00714L13.0134 8.08857C12.7476 8.95714 12.2066 9.75333 11.3903 10.4771C10.593 11.1829 9.60581 11.7529 8.42881 12.1871C7.25181 12.6214 5.98939 12.8476 4.64154 12.8657C4.98325 13.3362 5.38191 13.7524 5.83752 14.1143C6.29313 14.4581 6.85315 14.7838 7.51759 15.0914C7.9732 14.9467 8.44779 14.7567 8.94137 14.5214C9.43495 14.2862 9.91904 14.0419 10.3936 13.7886C11.4188 14.4762 11.9313 15.2724 11.9313 16.1771C11.9313 16.6657 11.751 17.1271 11.3903 17.5614C11.0486 17.9957 10.536 18.3486 9.8526 18.62C9.18816 18.8733 8.40983 19 7.51759 19C6.54941 19 5.52429 18.611 4.44221 17.8329C3.37912 17.0548 2.48688 16.1138 1.76549 15.01C1.04411 13.9062 0.683417 12.911 0.683417 12.0243C0.683417 11.2462 0.968174 10.6581 1.53769 10.26C1.76549 10.1152 2.05974 10.0248 2.42044 9.98857C2.80011 9.93429 3.30318 9.8981 3.92965 9.88C4.95477 9.84381 5.84701 9.68095 6.60637 9.39143C7.36572 9.1019 7.96371 8.7219 8.40034 8.25143C7.39419 8.32381 4.99274 8.45048 1.19598 8.63143C0.816304 8.43238 0.522055 8.17 0.313233 7.84429C0.104411 7.51857 0 7.17476 0 6.81286C0 6.08905 0.332217 5.5281 0.99665 5.13C3.5215 5.13 6.06533 5.07571 8.62814 4.96714C8.36237 4.53286 7.96371 4.10762 7.43216 3.69143C6.67281 3.74571 4.689 3.82714 1.48074 3.93572C1.19598 3.75476 0.977666 3.51952 0.825796 3.23C0.692909 2.92238 0.626466 2.60571 0.626466 2.28C0.626466 1.55619 0.920714 0.940953 1.50921 0.434287C4.09101 0.434287 6.71078 0.398096 9.36851 0.325715C12.0262 0.235239 14.2094 0.126667 15.9179 0C16.2786 0.199048 16.5444 0.452382 16.7152 0.76C16.9051 1.06762 17 1.39333 17 1.73714C17 2.38857 16.7342 2.95857 16.2027 3.44714C15.1016 3.42905 13.5639 3.45619 11.5896 3.52857C12.0073 3.96286 12.3205 4.36095 12.5293 4.72286C13.7443 4.65048 14.684 4.58714 15.3484 4.53286Z"
          fill="#FED402"
        />
      </g>
      <defs />
    </svg>
  );
};

export default Rupee;
