
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'

const initialState = {
    luckydraws: null,
    isLoading: false,
}

export const fetchAllLuckyDraws = createAsyncThunk('/allluckydraws', () => {
    return axios.get('/allluckydraws').then(res => res.data)
});





export const dashoardSlice = createSlice({
    name: 'dashboard',
    initialState,
    extraReducers: {
        [fetchAllLuckyDraws.pending]: (state) => {
            if (!state.luckydraws?.length)
                state.isLoading = true;
        },
        [fetchAllLuckyDraws.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.luckydraws = action.payload?.luckydraw;
        },
        [fetchAllLuckyDraws.rejected]: (state) => {
            state.isLoading = false;
        },

    },
})

export default dashoardSlice.reducer;


