import React from "react";

const MissionStatus = ({ status, message ,className }) => {
  if (status == "Redeem") return null;
  const generateStatus = () => {
    if (status == "Approved") {
      return "Mission Completed";
    }
    if (status == "Submit") {
      return "Submission Waiting For Approval";
    } else if (status == "Reject") {
      return "Submission Rejected";
    }
  };
  return (
    <>
      {/* <ProgressBar percentage={50} className="mission_progress">
                <div className="progress_content">
                  {each?.current || 0} / {each?.number_of_referals_required}
                </div>
      </ProgressBar> */}
      <p className={`mission_status ${status} ${className}`}>
        {message || generateStatus()}
      </p>
    </>
  );
};

export default MissionStatus;
