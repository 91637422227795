import React, { useState } from "react";
import { useEffect } from "react";
import { FiChevronDown } from "react-icons/fi";
import "./DropDownMenu.css";


const DropdownMenu = ({ dropdownData, onChange }) => {
  const [searchType, setSearchType] = useState();
  useEffect(() => {
    if (dropdownData?.length) {
      setSearchType(dropdownData?.[0]);
    }
  }, [dropdownData]);
  return (
    <div>
      <div className="relative">
        <p className="active_pill blue color_blue drop_menu_label">
          {searchType?.label}
          <span className="chevron_down icon_cont">
            <FiChevronDown size={20} className="color_blue" />
          </span>
        </p>
        <div className="drop_menu_cont absolute">
          <ul className="menu_list">
            {dropdownData?.map((each, i) => (
              <li
                key={i}
                onClick={() => {
                  setSearchType(each);
                  onChange(each);
                }}
                className={`menu_item ${
                  (each.id == searchType?.id && searchType?.id) ? "active" : ""
                }`}
              >
                {each.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DropdownMenu;
