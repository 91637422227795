import React from "react";
import { ClipLoader, BounceLoader } from "react-spinners";
import "./Loader.css";

const Loader = ({ loading, size = 50 , color }) => {
  return (
    <div className="loader-root">
      <BounceLoader color={color || "#ffffff"} loading={loading} size={size} />
    </div>
  );
};

export default Loader;
