import Bell from "./Bell";
import Menu from "./Menu";
import Rupee from "./Rupee";
import User from "./User";

export const navIcons = {
    bell_icon: <Bell />,
    rupee_icon: <Rupee />,
    user_icon: <User />,
    menu_icon: <Menu />
}