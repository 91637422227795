import React, { useEffect } from "react";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import { useDispatch, useSelector } from "react-redux";
import { fetchWithdrawalHistory } from "../../redux/WalletSlice";
import HistoryCard from "../WalletInfo/HistoryCard";

const WithdrawalList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchWithdrawalHistory());
  }, []);
  const { isLoading, withdrawal_history } = useSelector(
    (store) => store.wallet || {}
  );
  console.log(withdrawal_history, "some history");
  return (
    <>
      <GoBackHeader />
      <div className="page p-20">
        <div className="withdrawal_history">
          {withdrawal_history?.map((each) => (
              <HistoryCard
                  className={each.status||""}
                  date={each.created_at || ""}
                  isDefault_color
                  points={each.amount || ""}
                  title={each?.status||""}
              />
          ))}
        </div>
      </div>
    </>
  );
};

export default WithdrawalList;
