import React from "react";
import "./LotteryDetails.css";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import { myTicketsData, participantsData } from "./data";
import { useState } from "react";
import Participants from "./Participants";
import MyTickets from "./MyTickets";
import GetNumberModal from "./GetNumberModal";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import parse from "html-react-parser";
import CustomImage from "../../components/CustomImage/CustomImage";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchWallet } from "../../redux/user/userSlice";
import Countdown from "react-countdown";

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <div>Lucky Draw Revealed</div>;
  } else {
    let format = "";
    if (days) format += `${days} d : `;
    if (hours) format += `${hours} h : `;
    if (minutes) format += `${minutes} m : `;
    if (seconds) format += `${seconds} s`;
    return format;
  }
};

const LotteryDetails = () => {
  const [lotteryData, setLotteryData] = useState({});
  const [myTicketsData, setMyTickets] = useState(null);
  const [participantsData, setParticipantsData] = useState(null);
  const [activeTab, setActiveTab] = useState("myTickets");
  const [isExpired, setIsExpired] = useState(false);
  const [showModal, setShowModal] = useState({
    show: false,
    ticket_number: null,
  });
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const { user } = useSelector((store) => store?.user?.user || {});

  const dispatch = useDispatch();
  const getNumberClickHandler = () => {
    //setShowModal(true);
    setLoading(true);
    let payload = {
      user_id: user?.id,
      game_id: id,
    };
    axios
      .post(`getNumber`, payload)
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === 200) {
          setShowModal({
            show: true,
            ticket_number: res?.data?.data?.ticket_number,
            type: "success",
          });
          fetchMyTickets();
          dispatch(fetchWallet());
        } else {
          if (res?.data?.message == "Low Balance") {
            setShowModal({
              show: true,
              type: "failure",
              ap: lotteryData?.points_per_ticket || 0,
            });
          }
          toast.error(res?.data?.message || "Failed to get a number");
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        toast.error(e?.response?.data?.message);
      });
  };

  const fetchLotteryDetails = () => {
    setLoading(true);
    axios
      .get(`luckydraw/${id}`)
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === 200) {
          setLotteryData(res?.data?.luckydraw);
          let EndDate = new Date(res?.data?.luckydraw?.end_date_time);
          let dateNow = new Date();
          if (EndDate < dateNow) {
            setIsExpired(true);
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };
  const fetchMyTickets = () => {
    setLoading(true);
    let payload = {
      user_id: user?.id,
      game_id: id,
    };
    axios
      .post(`userNumber`, payload)
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === 200) {
          setMyTickets(res?.data?.data || []);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const fetchParticipants = () => {
    setLoading(true);
    let payload = {
      lottery_id: id,
    };
    axios
      .get(`/participantUsername`, { params: payload })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === 200) {
          setParticipantsData(res?.data?.data || []);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };
  useEffect(() => {
    fetchLotteryDetails();
    fetchMyTickets();
    fetchParticipants();
  }, []);

  const navigate  = useNavigate()

  return (
    <div className="page lottery_detail_page">
      <Loader loading={loading} />
      {showModal?.show && (
        <GetNumberModal
          type={showModal?.type}
          data={showModal}
          setShow={setShowModal}
        />
      )}
      <GoBackHeader />
      <div className="p-20">
        <div className="lottery_image_cont">
          <CustomImage isNormal src={lotteryData.game_image} />
        </div>
        <div className="winning_prize_cont mb-10">
          <div className="icon">
            <img src={require("../../assests/v2/cup.png")} alt="" />
          </div>
          <div className="content">
            <p className="light_title">Total Winning Prize amount</p>
            <p className="bold_title">
              INR {lotteryData.winning_prize_amount || 0}
            </p>
          </div>
        </div>
        <div className="winning_prize_cont">
          <div className="icon">
            <img src={require("../../assests/v2/timer.png")} alt="" />
          </div>
          <div className="content">
            <p className="light_title">Lucky draw Reveals in</p>
            <p className="bold_title">
              <Countdown
                date={new Date(lotteryData.end_date_time)}
                renderer={renderer}
              />
            </p>
          </div>
        </div>
        <button
          onClick={getNumberClickHandler}
          className="btn btn-primary mb-20"
          disabled={isExpired}
        >
          Get Number with {lotteryData?.points_per_ticket || 0} AP
        </button>
        <div>
          <p className="section-title">{lotteryData?.game_title || ""}</p>

          <p className="lottery_description">
            {parse(lotteryData?.game_description || "")}
          </p>
        </div>
        <p className="results_refer_lottery mt-20 lottery_description">
          Result will be refer to{" "}
          <span
            onClick={() => navigate("/result-refer")}
            className="red pointer bold "
          >
            lottoland
          </span>
        </p>
        <div className="pt-20 mt-20">
          <div className="tabs flex mb-20">
            <div
              onClick={() => {
                setActiveTab("myTickets");
              }}
              className={`tab flex-1 ${
                activeTab == "myTickets" ? " active" : ""
              }`}
            >
              My Tickets
            </div>
            <div
              onClick={() => {
                setActiveTab("participants");
              }}
              className={`tab flex-1 ${
                activeTab == "participants" ? " active" : ""
              }`}
            >
              Participants
            </div>
          </div>
          {activeTab == "myTickets" &&
            (myTicketsData?.length ? (
              <MyTickets ticketsData={myTicketsData} />
            ) : (
              <p className="no-data-msg">No Tickets Yet</p>
            ))}
          {activeTab == "participants" &&
            (participantsData?.length ? (
              <Participants participants={participantsData} />
            ) : (
              <p className="no-data-msg">No Users Participated Yet</p>
            ))}
        </div>
      </div>
    </div>
  );
};

export default LotteryDetails;
