import { reloadToLoginofUnauthenticated } from "../utils/utils";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      let { user } = store.getState();
      console.log(user)
      if (user?.user?.token) {
        config.headers.Authorization = `Bearer ${user?.user?.token||user?.data?.token}`;
      }
      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use(function (response) {
    if (response.status == 401) {
     // reloadToLoginofUnauthenticated()
    }
    return response;
  }, function (error) {

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  })
}
