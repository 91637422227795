import React from "react";

const Bell = (props) => {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_482_1662)">
        <path
          d="M20.7232 16.4935C20.1654 15.536 19.5003 13.7189 19.5003 10.5V9.83478C19.5003 5.68305 16.1613 2.28058 12.0571 2.25019C12.0379 2.25009 12.0189 2.25 11.9998 2.25C10.0108 2.25013 8.10323 3.04036 6.69682 4.44688C5.29041 5.85339 4.50032 7.76097 4.50034 9.75V10.5C4.50034 13.7185 3.83502 15.5357 3.27692 16.4931C3.1437 16.721 3.07299 16.98 3.07191 17.244C3.07084 17.5079 3.13944 17.7675 3.27079 17.9965C3.40214 18.2254 3.59159 18.4157 3.82 18.548C4.04842 18.6803 4.3077 18.75 4.57166 18.75H19.4283C19.6922 18.75 19.9515 18.6803 20.1799 18.548C20.4082 18.4158 20.5977 18.2256 20.7291 17.9967C20.8604 17.7677 20.9291 17.5082 20.928 17.2443C20.927 16.9804 20.8564 16.7214 20.7232 16.4935ZM14.9933 20.25H8.99329C8.79438 20.25 8.60361 20.329 8.46296 20.4697C8.32231 20.6103 8.24329 20.8011 8.24329 21C8.24329 21.1989 8.32231 21.3897 8.46296 21.5303C8.60361 21.671 8.79438 21.75 8.99329 21.75H14.9933C15.1922 21.75 15.383 21.671 15.5236 21.5303C15.6643 21.3897 15.7433 21.1989 15.7433 21C15.7433 20.8011 15.6643 20.6103 15.5236 20.4697C15.383 20.329 15.1922 20.25 14.9933 20.25Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_482_1662">
          <rect height="24" width="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Bell;
