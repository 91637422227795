import React from "react";
import "./HowItWorks.css";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
const HowItWorks = () => {
  return (
    <div className="page">
      <GoBackHeader />
      <div className="p-20">
        <p className="section-title">How it Works?</p>
        <ol className="ordered_list">
          <li>
            Apoint is a Affliate Point , That helps you to Join into lotteries and win a big amount of cash or prizes.
          </li>
          <li>
            You can get APoints by claiming from daily rewards
          </li>
          <li>APoints can also Earn by completing the missons</li>
        </ol>
        <div className="video_cont">
          <iframe src="https://www.youtube.com/embed/mLf8EgSThz0"></iframe>
        </div>
        <p className="section-title mt-30">More Benifits</p>
        <ol className="ordered_list">
          <li>
            The cash Earn by BjLottery Can be withdraw into Your bank account in 2 days.
          </li>
          <li>
            The winning Prizes Can be Devlivered into your address in Just within a Week.
          </li>
          <li>You Get More Chance of Winning Big Prizes</li>
        </ol>
      </div>
    </div>
  );
};

export default HowItWorks;
