import React from "react";
import "./UserPage.css";
import ProfileSettingButton from "./ProfileSettingButton";
import { my_icons } from "../../assests/jsxSvgs/jsxSvgsExport";
import GoBackButton from "../../components/GoBackButton/GoBackButton";
import { useSelector } from "react-redux";
import { levelCalculator } from "../../redux/user/userSlice";

const UserPage = () => {
  const { user } = useSelector((store) => store?.user || {});
  const level = useSelector(levelCalculator)

  return (
    <>
      <GoBackButton />
      <div className="page user_page">
        <div className="top_section"></div>
        <div className="circle"></div>
        <div className="profile_image_cont">
          {!user?.user?.logo ? (
            my_icons.user
          ) : (
            <img src={user?.user?.logo} alt="" />
          )}
        </div>
        <div className="bottom_section">
          <div className="user_details">
            <p className="username">{user?.user?.name || ""} ( {level} ) </p>
            <p className="email">{user?.user?.email || ""}</p>
          </div>
          <div className="action_buttons p-20">
            <ProfileSettingButton
              title="My account Settings"
              route="/account-settings"
            />
            {/* <ProfileSettingButton title="Language Settings" /> */}
            {/* <ProfileSettingButton title="Wallet Settings" /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPage;
